.automations-logs-main-table-wrapper {
    height: 80vh;
    overflow-y: scroll;
}
.automations-logs-main-table-wrapper  .automations-table tr td {
    font-size: 12px ! important;
    border: 1px solid #999 ! important;
    white-space: nowrap;
    text-align: left ! important
}
.automations-logs-main-table-wrapper  .automations-table .targeting-table-heads {
    padding: 8px 20px;
    z-index: 30;
    opacity: 100%;
    border-right: 1px solid white;       
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 20;
    text-wrap: nowrap;
}
