.topDiv {
    background-color: #F5F5F5;
}

.marketing-body {
    background-color: #EEEEEE;
}

.marketing-reports {
    color: #212121;
}

.marketing-reports-table-wrapper {
    /* height: 450px ! important; */
}

.marketing-reports .targetinng-table-heads {
    white-space: nowrap;
    padding: 8px 64px 8px 8px ! important;
    text-align: left ! important;
    font-size: 14px ! important;
}

.marketing-reports-table-wrapper table th {
    position: sticky;
    top: 0px;
    z-index: 40;
    border-right: 1px solid #FFF;
}

.marketing-reports-table-wrapper table tbody tr td {
    padding: 4px 8px ! important;
    font-size: 12px ! important;
    border: 1px solid #999 ! important;
    white-space: nowrap;
    text-align: left ! important;
}

.wraptext.marketing-reports-table-wrapper table tbody tr td {
    white-space: normal;
}

.wraptext.marketing-reports-table-wrapper table tbody tr td.targetinng-second-column {
    min-width: 250px;
}

.wraptext.marketing-reports-table-wrapper table tbody tr td.targetinng-second-column.product-child {
    min-width: auto;
}

.marketing-reports-table-wrapper {
    border-radius: 8px;
}

.marketing-reports-container .tableRb {
    border: none ! important;
}

.marketing-reports-table-wrapper .mt-table .targetinng-second-column {
    position: sticky;
    left: 0px;
    z-index: 21;

    height: auto;
    border-right: 1px solid #999 ! important;
    box-shadow: 2px 1px 6px #999;
}

.marketing-reports-table-wrapper .mt-table .targeting-second-column {
    position: sticky;
    left: 0px;
    z-index: 21;

    height: auto;
    border-right: 1px solid #999 ! important;
    box-shadow: 2px 1px 6px #999;
}

.marketing-reports-table-wrapper .mt-table thead .targetinng-second-column {
    background-color: #FF8364;
    top: 0px;
}

.marketing-reports-table-wrapper .mt-table tbody .targetinng-second-column {
    background-color: white;
}

.reporting-select>.select-opt {
    font-size: 12px ! important;
    height: auto;
    padding: 8px;
    font-weight: 500;
    justify-content: flex-start;
    align-items: flex-start;
    white-space: nowrap;
    min-width: 156px;
}

.reporting-select {
    width: max-content;
    min-width: 156px;
}

.min-h-300 {
    min-height: 300px;
}

.min-h-500 {
    min-height: 500px;
}

.h-300 {
    height: 300px;
}

.h-500 {
    height: 500px;
}

.max-h-300 {
    max-height: 300px;
}

.max-h-500 {
    max-height: 500px;
}

.f-20 {
    font-size: 20px;
    line-height: 1.4;
}

.tableSummary {
    font-size: 14px;
    font-weight: 800;
}

.marketing-stream-table-wrapper {
    height: 450px ! important;
}

.marketing-stream .campaig-list-head {
    background-color: #FFF ! important;
    color: #212121 ! important;
    box-shadow: none ! important;
    border-right: none ! important;
    padding: 4px ! important;
    font-size: 12px ! important;
}

.marketing-stream-table-wrapper table tbody tr td {
    padding: 4px ! important;
    font-size: 11px ! important;
    border: none ! important;
}

.marketing-stream-table-wrapper {
    border-radius: 8px;
}

.marketing-stream-container .tableRb {
    border: none ! important;
}

.marketing-stream .targetinng-table-heads {
    white-space: nowrap;
    padding: 8px 64px 8px 8px ! important;
    text-align: left ! important;
    font-size: 14px ! important;
}

.marketing-stream-table-wrapper table th {
    position: sticky;
    top: 0px;
    z-index: 40;
    background-color: white ! important;
    color: #212121 ! important;
}

.marketing-stream-table-wrapper table tr th,
.marketing-stream-table-wrapper table tr td {
    border: none;
}

.marketing-stream-table-wrapper table tbody tr td {
    padding: 4px 8px ! important;
    font-size: 12px ! important;
    border: none ! important;
    white-space: nowrap;
    text-align: left ! important;
}

.marketing-stream-table-wrapper table tbody tr,
.marketing-stream-table-wrapper table thead tr {
    border: none ! important;
}

.raw.marketing-stream-table-wrapper table tbody tr {
    border-bottom: 1px solid #999 ! important;
}

.marketing-stream-table-wrapper .mt-table .targetinng-second-column {
    position: sticky;
    left: 0px;
    z-index: 21;

    height: auto;
    /* border-right: 1px solid #CCCC ! important; */
    box-shadow: 2px 1px 6px #999;
}

.marketing-stream-table-wrapper .mt-table thead .targetinng-second-column {
    background-color: white;
    top: 0px;
}

.marketing-stream-table-wrapper .mt-table tbody .targetinng-second-column {
    background-color: white;
}

/* to change */
.marketing-stream-table-wrapper {
    overflow-x: hidden ! important;
    overflow-y: hidden ! important;
}

.marketing-stream-table-wrapper:hover {
    overflow-x: auto ! important;
    overflow-y: auto ! important;
}

.marketing-stream-table-wrapper.filter:hover {
    overflow-x: hidden ! important;
    overflow-y: hidden ! important;
}


.marketing-stream-table-wrapper::-webkit-scrollbar-thumb {
    background: #9DA2B9;
    border: 5px rgb(255, 255, 255) solid;
    height: 12px;
    width: 12px;
    border-radius: 20px;
}

/* Buttons */
.marketing-stream-table-wrapper::-webkit-scrollbar-button {
    display: block;
    height: 13px;

}

.marketing-stream-table-wrapper::-webkit-scrollbar-button:decrement:start {
    display: none;
    height: 13px;
    width: 16px;
    background: #F6F4FF;
    border-radius: 0px 0px 0px 7px;
    background-repeat: no-repeat;
    background-position: center center;

}

.marketing-stream-table-wrapper::-webkit-scrollbar-button:vertical:decrement:start {
    display: none;

}

.marketing-stream-table-wrapper::-webkit-scrollbar-button:vertical:increment:end {
    display: none;
}

.marketing-stream-table-wrapper::-webkit-scrollbar-button:decrement:end {
    display: none;

}

.marketing-stream-table-wrapper::-webkit-scrollbar-button:increment:start {
    display: none;

}

.marketing-stream-table-wrapper::-webkit-scrollbar-button:increment:end {
    display: none;
}

.marketing-reports-table-wrapper table tbody tr.highlight td {
    font-weight: 600;
    color: #4D4084 !important;
}

.highlight,
.highlight .targetinng-second-column {
    /* background-color: #F3F1FC ! important; */
    background-color: #C5DCFC ! important;
}

.cursor-pointer {
    cursor: pointer;
}

.tdIcon {
    height: 12px;
    width: 12px;
}

.productIcon {
    height: 36px;
    width: auto;
    object-fit: cover;
}

.marketing-reports-table-wrapper table tbody tr.childRows td {
    font-weight: 300;
    background-color: #F2F7FE ! important;
    color: #4C646A !important;
    font-size: 11px ! important;
}
.marketing-reports table tbody tr.childRows td {
    font-weight: 300;
    background-color: #F2F7FE ! important;
    color: #4C646A !important;
    font-size: 11px ! important;
}

.childRows .tableSummary {
    /* font-weight: 500; */
    background-color: #f3f1fc ! important;
    color: #4C646A !important;
    font-size: 12px ! important;
}

.productradiobutton .btnGroup {
    float: right;
}

.productradiobutton .btnGroup .radioBtn {
    width: max-content;
}

.text-loader-variant {
    color: #4D4084 !important;
}

.tooltip-content {
    background-color: #f4f6f8;
    border: 1px solid #ddd;
    color: #1c1b1f
}

.tooltip-arrow {
    position: absolute;
    top: 6%;
    right: -11px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid #ddd;
}

.mt-table.marketing-reports {
    min-width: 100%;
}

.targetinng-table-heads.selected {
    color: #333 ! important;
}

.sortSelected {
    filter: brightness(100%) saturate(0%) invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%) !important;
}

.searchIcon {
    width: 16px;
    height: 16px;
    margin-top: -1px !important;
}

.percentage-up-down-container.reports {
    align-items: center;
}

.percentage-icon>img {
    height: 14px;
    width: auto
}

.comparison-view-wrapper.marketing-reports-table-wrapper table th {

    border-right: 1px solid #999 ! important;
}

.comparison-view-wrapper.marketing-reports-table-wrapper table tbody tr td {
    border-bottom: 1px solid #999 ! important;
    border-right: 1px solid #999 ! important;
    /* color:#000; */
}

.comparison-view-wrapper.marketing-reports-table-wrapper table tbody tr td.targetinng-second-column {
    border-right: 1px solid #999 ! important;
}

.reports .percentage-desc {
    font-size: 9px;
    font-weight: 300;
}

.filterIcon {
    height: 16px;
    width: 16px;
}

.reports-filter-table-wrapper {
    max-height: 400px;
    overflow-y: scroll;
}

.reports-filter-table-wrapper::-webkit-scrollbar {
    /* display: none; */
    width: 0px;
    height: 0px;
}

.table.reports-filter-table {
    --bs-table-bg: #FFF ! important;
    font-size: 14px;
    color: #1c1b1f
}

.form-control-sm {
    background-color: #FFF ! important;
    color: #4c646a ! important;
    width: 72px;
    padding: 0px 4px;
    border: 1px solid #bbb ! important;
    height: max-content ! important;
}

.form-control-sm:focus-visible {
    outline-color: #ccc;
}

.form-control-sm:disabled {
    --bs-table-bg: #f7f7f7 !important;
    background-color: #f7f7f7 !important;
}

.table.reports-filter-table tbody tr td {
    white-space: nowrap;
    padding: 4px 8px;
    color: #1c1b1f
}

.table.reports-filter-table tbody tr {
    vertical-align: middle;
    color: #1c1b1f
}

.dotted-redirect-link {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: .1em;
    color: #4C646A;
}

.dotted-redirect-link:hover {
    color: #4C646A;
}

.primary-inactive-btn.apply-filter {
    height: auto ! important;
    border-color: #ddd;
    display: flex;
}

.largeFilters {
    flex-wrap: wrap;
}

.largeFilters>.filterItem {
    min-width: 22%;
}

.filterItem {
    flex-grow: 1;
}


.popupProductImage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popupProductImage img {
    max-width: 50%;
    max-height: 50%;
}

.paginationtext {
    color: #6c757d;
    /* margin-top: 1.5rem; */
    justify-content: flex-end;
    display: flex;
    font-size: 12px;
}

.downloadChartWrapper {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: -8px;
}

.DownloadIcon {
    height: 24px;
    width: 24px;
}

.downloadChartWrapper.pie {
    top: -30px;
}
.primary-active-btn.square {
    border-radius:4px ! important;
    background-color: #057894;
}
.primary-active-btn.square:hover {
    border-radius:4px ! important;
    background-color: #057894;
}
.primary-inactive-btn.square {
    border-radius:4px ! important;
}