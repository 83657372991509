/* .filetr-modal-for-targeting-main-container .edit-bid-for-targetinng-moda {
    background-color: aqua;
}

 .edit-bid-dialogue {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* .edit-bid-for-targetinng-conntennt{
    background-color: red;
    
} */
.edit-bid-for-targetinng-dialogue {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 150px;
    height: 100%;
    overflow-y: hidden;
    overflow: hidden;

    /* height: 55.92px;
     */
}

.targeting-filter .edit-bid-for-targetinng-conntennt {}

.targeting-filter {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* background-color: aqua; */
    overflow: hidden;
    padding: 0px
}

.targeting-filter .modal-body {
    padding: 0px;
    width: 436.2px;
    height: 283px;
}

.action-by-btn label {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Neutral40 */

    color: #4F5366;
    cursor: default;
}
.action-by-btn input{
    cursor: pointer;
}
.targeting-bid-config-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    padding: 7px;

}

.targeting-btn-container-for-bid {
    /* padding-top: 15px; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.targetig-bid-conntainer-for-set-by {
    justify-content: space-between;
    padding: 3px 96px;
}

.targeting-bid-config-content-containet {
    padding: 20px 0px;
}

.targeting-edit-bid-set-bid-cal-box input {
    box-sizing: border-box;

    width: 120px;
    height: 32px;


    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
}

.set-as-iput-text {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #4F5366;
    outline: none;
}

/* .targeting-edit-bid-set-bid-box{
    margin-left: -20px;
}
.targeting-edit-bid-set-bid-cal-box{
    background-color: rgb(209, 132, 30);
    

} */