.filterIcon {
  height: 24px;
}

/* Master Ads Table Css Edit  */

.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table {
  border-collapse: initial;
}

.alignment-top-master-ads {
    top: 260px;
}

.master-ads-table .ads-td-second {
  position: sticky;
  /* left: 68px; */
  left: 75px; /*MS-258 when horizonatal scroll 2nd clm not sticky */
  z-index: 21;
  width: 248px;
  background-color: #fff;
  /* height: 52px; */
  /* background:    #F3F1FC; */
  /* padding: 12px 15px; */
  height: auto;
  border-bottom: 0.5px solid #cccccc; 
  border-right: 1px solid #CCCC;
  box-shadow: 2px 1px 6px #cccc;  
}

.master-ads-table .ads-td-first {
  position: sticky;
  left: 0;    
  z-index: 21;
  background-color: #fff;
  /* padding: 12px 15px; */
  height: auto;
}

.master-ads-table th:nth-child(2) {
  background-color: red;
}

.mad-tab-border {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table th:nth-child(2) {
  position: sticky;
  /* left: 68px; */
  left: 75px; /*MS-258 when horizonatal scroll 2nd clm not sticky */
  top: 0;
  z-index: 45;
  opacity: 100%;
  border-right: 1px solid #e47518;
  box-shadow: 2px 1px 6px #c9705a91;
  /* padding-left: 20px; */
  padding: 0px 20px;
}



.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table .ad-campaign-details td {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  background-color: #F2F7FE;;
  text-align: left;
}
.chid-row-campaign{
  width: 180px;
  overflow-wrap: break-word;
}
.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table tbody tr .stats-data-col,
.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table th {
  text-align: right;
  /* background-color: red; */

}

.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table th:nth-child(1) {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 45;
  opacity: 100%;
  border-right: 1px solid #FFFFFF;
}


.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table tr td {
  border-right: 0.5px solid #cccccc;
  border-bottom: 0.5px solid #cccccc;
}

.master-ads-main-table-wrapper {
  /* overflow-y: scroll; */
   overflow-y: auto; /*MS-258 scroll gap when scroll not needed */
  /* height: 88vh; */
  height: 90vh;
 
}

.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table .targeting-table-heads {
  padding: 8px 20px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 30;
  opacity: 100%;
  border-right: 1px solid white;       
  text-align: left;
}

.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table .active-filter-column {
  border: 2px solid #320f06;
  z-index: 35;
}

.campaign-list-table .ads-td-second .main-child-table-product-containerr {
  display: flex;
  /* gap: 1rem; */
  width: 300px;
}

.campaign-list-table
  .ads-td-second
  .main-child-table-product-containerr
  .right-circle-image-container
  .image-product-table-second-td {
  height: 30px;
  width: 30px;
  background: #d9d9d9;
  border-radius: 10px;
}

.campaign-list-table .ads-td-second .product-data-container {
  display: flex;
  flex-direction: column;
}

.product-ads-description {
  text-align: start;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  color: #4c646a;
}

.campaign-list-table .ads-td-second .main-child-table-product-containerr .product-child-row-button {
    margin-left: auto;
    cursor: pointer;
}

.master-ads-calendar-container {
  position: relative;
  margin-left: 16px;
}

.calender-masterads-container {
    background: #ffffff;
    /* height: 1000px;
    height: 435px; */
    position: absolute;
    z-index: 99;
    /* top: 200px; */
    width: 892.4px;
    display: flex;
    flex-direction: row;
    display: flex;
    box-shadow: 0px 2px 10px rgb(77 65 132 / 20%);
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
    padding-top: 30px;
    background: #ffffff;
    /* height: 1000px; */
    height: 435px;
    position: absolute;
    z-index: 99;
    /* top: 200px; */
    width: 892.4px;
    display: flex;
    flex-direction: row;
    display: flex;
    right: 0px;
    top: 42px;
    box-shadow: 0px 2px 10px rgb(77 65 132 / 20%);
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
    padding-top: 30px;
    height: auto;
}

.master-ads-table-wrapper .master-ads-main-table .master-ads-main-table-wrapper .master-ads-table .ad-campaign-details {
  text-align: end;
  /* padding-right: 35px; */
}
.product-name-dyanamic-width-foe-master-ad{
  width: 300px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: left;

} 
/*parenr row */
.main-parent-table-product-container .right-circle-image-container{
  margin-right: 10px;
  /* background-color: yellow; */

}
/*child row */
.main-child-table-product-containerr .right-circle-image-container{
  margin-right: 9px;
  /* background-color: #4c646a; */

}

.child-checkbox-containet-master-ad{
  display: flex;
  align-items: center;

}

.ma-loader:nth-child(1) {
  background-color: white;
  position: sticky;
  left: 0px;
  top: 0;
  z-index: 44;

}

.ma-loader:nth-child(2) {
  background-color: white;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 44;
  left: 68px;
  border-right: 1px solid #CCCC;
  box-shadow: 2px 1px 6px #cccc;

}
/* .calender-masterads-container
{
  background: #FFFFFF;
     height: auto; 
    z-index: 99 !important;;
    position: fixed !important;;
    top: 210px !important;;
    flex-direction: row !important;;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2) !important;;
    border-radius: 8px !important;
    right:20px !important;
} */

/* MS-86 17-5-23 */

.bulk-upload-option {
  position: relative;
}

.bulk-upload-option {
  display: flex;
  align-items: center;
}

.toggle-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  
}

.toggle-icon {
  font-size: 20px;
  margin-right: 5px;
  transform: rotate(90deg);
  position: relative;
  top: -10px;
}

.options {
  position: absolute;
  top: 100%;
  right: 0px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  border-radius: 4px; /* Optional: Add border radius for a rounded appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle box shadow */
}

.option-label {
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
}
  

.option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.option-icon {
  margin-right: 5px;
}

/* 220 highlighted rows`s 2nd clm  */
.master-ads-table .highlight .ads-td-second
{
  background: #F3F1FC ;
}

/* 220 highlighted rows`s 1nd clm  */
.master-ads-table .highlight .ads-td-first{
  background: #F3F1FC ;
}

/* 220 highlighted rows`s 1nd clm  */
.search-term-table-wrapper .search-term-main-table .search-term-table .highlight .ads-td-second{
  background: #F3F1FC ;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody .highlight .second-column{
  background: #F3F1FC ;
}

.campaign-list-table-data .campaign-list-main-table .main-campaignlist-table-body-container table tbody .highlight .first-column-sw-btn{
  background: #F3F1FC ;
}