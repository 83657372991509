.marginTop20 {
    margin-top: 20px;
}

.noBottomMargin {
    margin-bottom: 0px;
}

.paddingRight10 {
    padding-right: 10px;
}

.marginRight10 {
    margin-right: 10px;
}

.productWrapper {
    display: flex;
    gap: 20px;

}

.product-set-table table th {
    white-space: nowrap;
    padding: 12px;
    text-align: left !important;
}

.tableProductText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
    margin-bottom: 0px;
    margin-left: 20px;
    /* display: flex; */
    /* gap: 20px; */

}


.tableSelProductText {
    
    max-width: calc(100vw - 410px);
    /* max-width: inherit; */
    margin-bottom: 0px;
 
    min-width: calc(100vw - 414px);
    display: flex;
    gap: 20px;
}

.tableSelProductWrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toolkitWrapper {
    display: flex;
    /* align-items: end; */
}

.createPortfolioBtn:hover {
    background-color: #6F62B0 !important;
    box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
    border-radius: 24px;
}

.addRemoveBtn {
    width: 40px;
}

.addRemoveBtn:hover {
    background-color: #6F62B0 !important;
    box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
    border-radius: 24px;
}

.pageTitleText {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;    
}

.cardStyle {
    background: #FFFFFF;
    border: 1px solid #E1DFEB;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
    border-radius: 8px;

    padding: 25px 32px 32px 32px;
    width: 100%;
}

.card-style-new {
padding: 32px 20px 32px 20px;
border-radius: 8px;
border: 1px;
gap: 10px;
width:100%;
background: #FAFBFB;
border: 1px solid #C9CCCF
}

.dropdown-text {
font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0px;
}

.textBoxWrapper {
    height: 40px;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    outline: 0px;
    padding-left: 10px;
    width: 100%;
}

.dropdownWrapper-product-set {
    display: flex;
    gap: 20px;
    align-items: end;
    margin-bottom: 10px;
}

.dropdown-customwidth-product-set {
    width: 400px;
}

.betweenText {
    margin: 0px;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #4f5366;
}

.betweenTextWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.tableHeaderWrapper {
    margin: 20px 0px;
    display: flex;
    gap: 20px;
    justify-content: end;
    align-items: center;
}


.captionText {
    min-width: 33%;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #4f5366;
}

.contentWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.createProductBtn {
    float: right;
    width: 160px;
}

.smart-ads-percent-symbol {
    right: 6px;
    top: 6px;
}

.smart-ads-list-table #smart-ads-table thead tr th:nth-child(2) {
    position: static;
    left: 0px;
}

.products-rules-set-container {
border-radius: 8px;
border: 1px solid #E1E3E5;
background: #FAFBFB;
height: 100%;
}

.products-rules-set-container .reset-container {
padding: 10px 20px 10px 20px;
border-radius: 8px 8px 0px 0px;
gap: 10px;
background: #E1E3E5;
}

.products-rules-set-container .conditions-container {
padding: 20px;
border-radius: 0px 0px 8px 8px;
padding: 10px 20px 10px 20px;
gap: 10px;
background: #FAFBFB;
}

.switch-btn-for-smart-ads {
    padding-left: 1.8em;
}

.empty-duration-container {
    height: 50px;
}

.smart-ads-desc-text {
font-family: "Roboto";
font-size: 16px;
font-weight: 600;
line-height: 28px;
letter-spacing: 0px;
text-align: left;
color: #636F72;
}

.switch-btn-disabled-smart-ads {
    pointer-events: none;
    opacity: 0.5;
}