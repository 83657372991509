.filter-logic-main-table {
    width: 100%;
    height: 90%;
    
    }
    
    .filter-logic-main-table-thead {
    align-items: center;
    text-align: center;
    background: #FF8364;
    border-radius: 0px 8px 0px 0px;
    color: white;
    
    }
    
    .filter-logic-main-table-tr {
    border: 1px solid #ccc;
    padding: 10px;
    }
    
    .filter-logic-main-table-inside-tr {
    border: 1px solid #9DA2B9;
    align-items: center;
    
    
    }
    
    .div-in-logic {
    display: flex;
    
    /* margin-left: 50px; */
    }
    
    .filetr-modal-main-container .modal {
    background-color: #64ff69;
    }
    
    .main-modal-className-for-filter .filter-modal-two-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    }
    
    .filter-table1 {
    width: 614.84px;
    margin-right: 20px;
    /* border-top: 5px solid red; */
    /* height: 357px; */
    border-radius: 20px;
    margin-bottom: 30px;
    /* height: 357px; */
    }
    
    .filter-table2 {
    width: 614.84px;
    /* height: 357px; */
    }
    
    .main-modal-className-for-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 1312.47px; */
    height: fit-content;
    padding: 20px 30px;
    
    }
    
    .filetr-modal-main-container .filter-modal-two-box-container .filter-table1 table thead tr {
    background-color: rgb(51, 255, 0);
    }
    
    .filetr-modal-main-container {
    background-color: #FF8364;
    }
    
    .filter-dialog {
    display: flex;
    justify-content: center;
    }
    
    .f-table-body-container thead {
    width: 142px;
    height: 44px;
    background: #FF8364;
    /* border: 1px solid grey;
    border-radius: 10px; */
    
    }
    
    
    .f-table-body-container td {
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
    width: 176px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 0px;
    font-size: 12px !important;
    }
    
    .input-disable {
    background-color: #CCCCCC;
    width: 130px;
    height: 32px;
    background: #F6F4FF;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 4px;
    text-align: center;
    }
    
    .input-clickable {
    width: 130px;
    height: 32px;
    text-align: center;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 4px;
    }
    
    .filter-logic-main-table th:first-child {
    border-radius: 8px 0 0 0;
    }
    
    .filter-logic-main-table th:last-child {
    border-radius: 0 8px 0px 0px;
    /* border: 1px solid white; */
    
    }

    /* MS-96 Filter logic table header and data left align */
    table.filter-logic-main-table thead th.filter-logic-main-table-tr{text-align: left;}
    table.filter-logic-main-table thead th.filter-logic-main-table-tr:nth-child(1){text-align: center;}
    table.filter-logic-main-table tbody td.tabledata-td.filter-logic-main-table-inside-td:nth-child(2) .d-flex.justify-content-evenly.align-items-center{    justify-content: flex-start !important;}
    
    @media screen and (max-width:1200px) {
    .main-modal-className-for-filter {
    display: flex;
    justify-content: center;
    /* width: 1000.47px; */
    height: fit-content;
    }
    
    .main-modal-className-for-filter .filter-modal-two-box-container {
    display: flex;
    flex-direction: column;
    
    }
    
    }
    