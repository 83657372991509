#product-table-header {
    text-align: start !important;
    min-width: 180px;
}

.table-header-text {
    width: 100% !important;
}

.main-modal-container {
    position: relative;
}

.position-check-2 {
    position: absolute;
    left: 31%;
    top: 17%
}

.product-table-first {
    text-align: start !important;
}

/* // Make the Table closer to each other */

.table-closer {
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 0px;
}

/* Product Table Data CSS  */

.product-container {
    display: flex;
    gap: 10px;
}

.product-img-container img {
    height: 71px;
    width: 71px;
}

.product-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.product-desc {
    justify-self: flex-end;
    margin-top: 15px;
}

.category-heading {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    width: 55px;
    height: 16px;
    text-transform: capitalize;
    color: rgba(77, 65, 132, 0.8);
}

.product-name {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* width: 193px; */
    width: 100%;
    /* height: 20px; */
    /* Neutral30 */
    color: #3D4050;
}

.product-desc {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3D4050;
    /* width: 116px;
    height: 20px; */
}


.bids-parent-container {
    display: flex;
    justify-content: space-between;
}

.bids-container {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    /* width: 87px;
    height: 40px; */
    /* Neutral30 */
    color: #3D4050;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

/* Asin's Table  */

.table-heading-style-asins {
    border-radius: 8px;
    background-color: #ffffff;
}

.table-body-asins {
    border-radius: 8px solid white;
    padding: 0px 12px;
}

/* //CHAGED */
/* .main-tableRb-body .tableRb table {
    border: none;
    width: 100%;
    height: 100%;
    overflow: scroll;
    border: 1px solid #CCCCCC;
} */
.sp-product-targettig-outer-container .generic-main-table-body-container table{
    border: none;
    width: 100%;
    height: fit-content;
    overflow: scroll;
    overflow-y: scroll;
    border: 1px solid #CCCCCC;
}



.asian-table{
    width: 100%;
}

/* .main-tableRb-body .tableRb table thead th {
    padding: 12px 20px;
} */
.sp-product-targettig-outer-container .generic-main-table-body-container table thead th{
    padding: 10px 0px
 
}
.generic-main-table-body-container {
    /* width: 478px;
    height: 554px; */
    /* height: 100%; */
    height: 580px;
    /* border: 1px solid #CCCCCC; MS-96 border seems some issue*/
    height: 570px;
}
.generic-main-table-body-container.keyword-targeting-modal {
     height: 710px;/* MS-258 table height issue */
}
.generic-main-table-body-container.keyword-targeting-modal-asin {
    height: 702px;/* MS-258 table height issue */
}

.asian-table-div{
    height: auto !important;  /*MS-258 ASIN table scroll issue*/
}
/* .generic-main-table-body-container .sp-product-targetting-table{
background-color: red;
} */
.table-body1 {
    background: #FFFFFF;
    border-radius: 8px;
    height: 570px; /*MS-258 3.gap in table*/
    /* background: #FFFFFF;
    border-bottom: 1px solid #868BA0;
    border-left: 1px solid #868BA0;
    border-right: 1px solid #868BA0;
    border: 1px solid #868BA0;
    border-radius: 8px; */
}
.table-body1 .main-tableRb-body{
    border: 1px solid #CCCCCC;
}
.table-body1 .main-tableRb-body .tableRb{
    border: none !important;
}
.table-body1 .main-tableRb-body .tableRb table
{
    border-collapse: inherit;
}
.table-body1 .main-tableRb-body .tableRb table td{
    border-radius: 8px;
    background: #fff;
}

.table-body2 {
    /* width: 478px; */
    /* height: 694px; */
    height: 710px; /*MS-258 table alignment point 11 */
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: -13px;
}

.text-area-aisn-container{
    /* background-color: red; */
    /* height: 615px; */
}
.asian-body1 {
    /* background-color: aqua; */
    /* height: 726px; */
}

/* .text-area-for-prdouct-targetting{
    height: 100%;
} */
.text-area-for-prdouct-targetting{
background-color: red;
height: 100%;
}
/* .table-rb-container-for-asian{
    background-color: pink;
} */
/* table {
    border-collapse: unset;
} */


/* // SP Product Targeting table Body CSS is breaking so Creating media queries for them here seperately  */






@media screen and (max-width:1380px) {
    .generic-main-table-body-container table thead th {
        font-size: 13px;
    }

    .generic-main-table-body-container thead tr th {
        padding: 12px 12px;
    }
}

@media screen and (max-width:1320px) {
    .generic-main-table-body-container thead tr th {
        padding: 12px 4px;
    }

    .generic-main-table-body-container  table tbody tr td {
        padding: 16px 8px;
    }
}