
.header-container {
    position: sticky;
    /* background-color: rgba(249, 249, 249, 1); */
    background: #FFFFFF;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    padding: 0px 15px;
    width: 100%;
    position: fixed;
    z-index: 150;
    top: 0;
  }
  
  .header-container .logo-container {
    display: flex;
    margin-left: 15px;
  }
  
  .logo {
    width: 150px;
    height: 37px;
  }
  
  
  
  .serch-bar-container-onopen-sidebar {
    display: flex;
    justify-content: center;
    /* margin-left: 120px; */
}

.serch-bar-container-close-sidebar {
    display: flex;
    /* margin-left: 120px; */
    
}

.client-name-header {
  top: 36px;
  left: 5px;
  font-size: 10px;
  text-align: center;
}

.nav-button-container {
    display: flex;
    flex: 4.2;
    width: 168px;
    justify-content: end;
    padding-right: 0 !important;
  }
  
  .nav-icon-container {
    display: flex;
    flex: 0;
    justify-content: center;
    align-items: center;
    /* padding-right: 30px;     */
}

.nav-icon {
    display: flex;
    justify-content: center;
  }
  
  .has-search .form-control {
    border-radius: 20px !important;
    padding-left: 49px ;
    width: 92%;
    border-radius: 20px !important;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    /* font-size: 14px; MS-96 search text change*/
    font-size: 14px;
    line-height: 20px;
    height: 40px;
   
}
.form-control::-webkit-input-placeholder { /* Edge */
  padding-left: 0px;
}



.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    padding-left: 19px;
    display: block;
    border-radius: 20px !important;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    margin-top: 1px;
}
    
  .navToggle {
    float: right;
    position: relative;
    width: 30px;
    height: 40px;
    padding: 0;
    background-color: transparent;
    transition-duration: 0.5s;
  }
  
  .navToggle:hover {
    cursor: pointer;
  }
  
  .navToggle .icon-left {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    top: 19px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.87);
  }
  
  .navToggle .icon-left:before {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.87);
    content: "";
    top: -10px;
    left: 0;
  }
  
  .navToggle .icon-left:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.87);
    content: "";
    top: 10px;
    left: 0;
  }
  
  .navToggle .icon-right {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    top: 19px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.87);
    left: 15px;
  }
  
  .navToggle .icon-right:before {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.87);
    content: "";
    top: -10px;
    right: 0;
  }
  
  .navToggle .icon-right:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.87);
    content: "";
    top: 10px;
    right: 0;
  }
  
  .navToggle.open .icon-left {
    transition-duration: 0.5s;
    background: transparent;
  }
  
  .navToggle.open .icon-left:before {
    transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
  }
  
  .navToggle.open .icon-left:after {
    transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
  }
  
  .navToggle.open .icon-right {
    transition-duration: 0.5s;
    background: transparent;
  }
  
  .navToggle.open .icon-right:before {
    transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
  }
  
  .navToggle.open .icon-right:after {
    transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
  }
  
  
  
  .toggle-dropdown {
    width: 100%;
    position: absolute;
    top: 72px;
    background-color: #f9f9f9;
    row-gap: 15px;
    opacity: 0;
    visibility: hidden;
  
  }
  
  .toggle-dropdown.open {
    visibility: visible;
    opacity: 1;
  
  }
  
  .toggle-dropdown .width-100 {
    width: 100%;
  }
  
  .header-container .row {
    flex-wrap: nowrap;
  }
  
  ul {
    list-style: none;
  }




  .fa-question-circle 
  {
      position:relative;
  }
  
  .fa-question-circle:after
  {
      background-color:rgba(0, 0, 0, .6);
      color: white;
      box-sizing:border-box;
      content: attr(data-tooltip);
      display:none;
      padding:5px;
      position:absolute;
      right: -30px;
      bottom: -55px;
      z-index:3;
      box-shadow: 0 0 3px #000;
      border-radius: 0px 10px 10px 10px;
  }

  .fa-question-circle:hover:after {
    display:block;
}

.dropdown-content-box {
    top: 110%;
    padding: 10px;
    background-color: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4F5366;
    border: 1px solid #BDBDC3;
    border-radius: 10px;
    z-index: 1000;
    right: 0;
}

.dropdown-content-box .dropdown-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid rgba(77, 65, 132, 0.15);
}

.header-container {
  padding-left: 130px;
}

.arrow-align-user{
  display: flex;
  align-items: center;
}


.profile-container {
  background-color: #F4F6F8;
  border-radius: 8px;
  height: 40px;
  max-width: 160px;
  padding: 4px 12px 4px 12px;
  /* min-height: 40px; */
}

.profile-columns-container {
  flex: 1;
}

.text-client-name {
  font-size: .7rem;
}










  
  
  @media screen and (max-width:991px) {
  
    .header-container .row {
        flex-wrap: wrap;
    }
    .header-container .logo-container {
        display: flex;
        margin-left: 0px;
      
    }
 
    
  }