.performance-sum-container {
    margin-top: 20px;
}

.performance-sum-btn-container {
    margin-top: 20px;
}



.performance-sum-category-table table tr td {
    text-align: center;
    padding: 12px 40px;
    border:1px solid #CCCCCC;
}

.performance-sum-category-table table tr th {
    text-align: center;
    padding: 12px 40px;

}

.performance-sum-category-table table tr th:first-child {
    text-align: start;
    padding: 12px 24px;

}

.performance-sum-category-table table tbody tr td:first-child {
    text-align: start;
    padding: 12px 24px;


}