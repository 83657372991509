.captital-first-letter {
    text-transform: capitalize;
}

.main-targeting-table-body-container {
    /* height: 519px; */
    /* height: 671px; */
    height: 88vh;
    overflow-y: auto;
}



.bottom-margin {
    margin-bottom: 16px;
}


.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tbody .second-column-child {
    position: sticky;
    /* left: 68px; */
    left: 75px; /*MS-258 when horizonatal scroll 2nd clm not sticky */
    z-index: 21;
    width: 248px;
    /* height: 52px; */
    background: #F2F7FE;
    /* 
    padding: 12px 15px; */
    height: auto;
    padding: 8px 20px;
    border-right: 1px solid #CCCC;
    box-shadow: 2px 1px 6px #cccc;


}

.second-column-child input {
    background-color: rgba(243, 241, 252, 100%);
    border: none;
}

.sort-icon {
    /* height: 12px; */
    /* margin: 0px 10px; */
    margin-left: 5px;
    /* display: flex; */
    align-items: center;

}

.stat-heads-with-icon {
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.check-colum-head label {
    /* font-size: 14px; */
    color: #ffffff;
}


.et-filter-options-container-formasterTargeting {
    display: flex;
    flex-direction: row;
    /* background: #FFFFFF; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15); */
    position: absolute;
    right: 0;
    /* top: 238px; */
    top: 700px;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    z-index: 87;
    position: absolute;
    /* background: #F0EDFF; */
    border: 1px solid #999EA4 ;
    background-color: white;
    border-radius: 8px 8px 8px 8px
}

.alignment-top-for-master-targeting {
    top: 729px !important;
}

.alignn-with-sug-bid-box {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 36px;
    background: #07A9D2;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: white;
    border-radius: 24px */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 36px;
    background: #07A9D2;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: white;
    border: 1px solid #999EA4;
    border-radius: 8px
}

.alignn-with-sug-bid-box-selective {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 36px;
    background: #07A9D2;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* border-radius: 7px 0px 0px 7px; */
    color: white;
}

.bid-manipulate-box {
    /* margin-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 36px;
    background: #E8FAFF;
    color: #4C4F6A;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 24px;
    border: 1px solid #BDBDC3; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 36px;
    background: #E8FAFF;
    color: #4C4F6A;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid #999EA4;
}

.bid-manipulate-box-selective {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 36px;
    background: #E8FAFF;
    color: #4C4F6A;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-left: 1px solid #4D4184;
}

.et-filter-options-for-master-ads button:nth-child(3) {
    border-radius: 0 8px 8px 0;
}

.selected-row-count {
    /* font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px; */
    display: flex;
    align-items: center;
    color: #000000;
    width: 123px;
}

.border-radius {
    border-radius: 0px 7px 7px 0px;

}

.campaign-count-circle {
    width: 40px;
    height: 20px;
    border-radius: 35px;
    color: white;
    background-color: #F3F1FC;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    /* identical to box height, or 100% */


    color: #4D4184;
}

.paginnation {
    border-bottom: 1px solid #CCCCCC;
    /* border-right:  1px solid #9DA2B9;
    border-left:  1px solid #9DA2B9; */
}

.paginnationn-sec-box {
    height: 55px;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
    border-right: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
}

.paginnation-static-text {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    text-transform: capitalize;

    color: #4C646A;
    padding-right: 30px;
    display: flex;
    align-items: center;
}

.pagination-arrow-conntainer {
    display: flex;
    justify-content: space-evenly;
    width: 330px;
}

.paginnation-count-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #4C646A;
}




.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tr .targetinng-table-heads-campaigntype-with-maginifine-icon {
    /* padding: 12px 40px; */
    padding: 8px 20px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 38;
    opacity: 100%;
    border-right: 1px solid white;
}



.targetinng-table-heads-suggbid-with-maginifine-icon {
    /* padding: 12px 40px; */
    padding: 8px 20px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 32;
    opacity: 100%;
    border-right: 1px solid white;
}

.targeting-selected-head {
    display: flex;
    justify-content: flex-start;
    width: 250px;

    /* padding: 12px 16px; */

}


/* .parent-targeting-checkbox label {
    padding-left: 10px;
} */

.child-targeting-checkbox label {
    padding-left: 10px;
    font-size: 12px;
}

.targeting-checkbox .form-check {
    width: 276px;
    text-align: center;


}

.targeting-checkbox .check-colum-head .form-check-input[type=checkbox] {

    /* margin-left: -31px; */
    /* margin-left: -13px; */
    display: flex;
    justify-content: flex-start;

}

.targeting-checkbox .check-colum-head label {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    color: red;

}

.targetinng-typ {
    background-color: #4D4184;
    flex-direction: row;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding: 12px 40px; */

    /* width: 190px; */
    width: 190px;
    /* height: 44px; */
    /* height: 44px; */
    background: #FF8364;
    /* background: #6469ff; */
    border-radius: 0px;
}

.other-col {
    background-color: #4D4184;
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    align-items: center;

    /* padding: 12px 40px; */

    /* width: 190px; */
    width: 100px;
    /* height: 44px; */
    /* height: 44px; */
    background: #FF8364;
    /* background: #6469ff; */
    border-radius: 0px;
}

.main-display-campaign {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.currennt-bid-td-container {
    background-color: #4D4184;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 148px; */
    width: 112px;
    height: 32px;
    background: #E8FAFF;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

.currentbid-rightbox {
    width: 72px;
    background: #E8FAFF;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 4px;
    outline: none;
    border: none;
    padding-left: 10px;
}

.selected-row-count {
    background-color: white;
    border-radius: 8px;
}

.find-icon {
    height: 17px;
    display: flex;
    align-items: center;
}

.fieldnamme-with-search-iconn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    /* width: 189px; */
    width: 144px;


}

.find-box-container {
    background-color: #4D4184;
}

.find-box-mainn-container {
    box-sizing: border-box;
    position: absolute;
    width: 302px;
    height: 350px;
    /* top: 7px; */
    margin-left: 80;
    z-index: 300;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    /* left: 80px; */
    left: 18px;
    top: 40px;

}

.search-list-of-data {
    height: 250px;
    width: 100%;
    overflow-wrap: break-word;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 50px;
    margin-bottom: 50px;

}

/* .find-box-card{
    display: flex;
    flex-direction: row;
} */

.search-box-infind-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px 10px 11px;
    position: absolute;
    top: 10px;
    margin-bottom: 10px;
    /* position: absolute; */
    /* width: 286.13px; */
    width: 286px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #BDBDC4;
    border-radius: 8px;
}

.finnd-input-text {
    flex-grow: 1;
}

.finnd-input-text input {
    border: none;
    outline: none;
    padding-left: 5px;
    width: 100%;
}

.finnd-input-text input::placeholder {
    /* font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px; */

    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #A7A7A7;

}

.search-result-text {
    /* font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px; */
    color: #63677B;
    overflow-wrap: break-word;



}

.search-result-row {
    /* height: 35px; */
    /* padding: 17px 10px; */
    /* width: 290px; */
    display: flex;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    /* padding-right: 20px; */
    /* height: 38px; */
    /* padding-bottom: 30px; */
    border-bottom: 1px solid rgba(77, 65, 132, 0.15);
    justify-content: flex-start;
    align-items: center;

}

.search-result-text label {
    padding-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;

    margin-right: 30px;
    padding-right: 10px;
    /* width: 230px; */
    word-break: break-word;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #63677B;
    /* identical to box height, or 133% */
    width: 214px;

    /* Neutral50 */

    color: #63677B;
}

.curret-bid-value-edit {
    /* width: 114px;
    height: 100%; */
    /* width: 100%; */
    background: #ffffff;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 4px;
    outline: none;
    border: none;
    padding-left: 10px;
    width: 77px;
    height: 30px;
}

.mt-seacrh-input-container .mt-main-seacrh-input-field {
    width: 254px;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(77, 65, 132, 0.2);
    border-radius: 8px;
    padding-left: 38px;
    outline: none;
    /* color: #000000; */

}

.mt-seacrh-input-container .mt-main-seacrh-input-field::placeholder {
    padding-left: 2px;
    /* font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px; */
    display: flex;
    align-items: center;
    color: #A7A7A7;

}

.turn-arrow-black {
    rotate: 180deg;
}

.end-page-clicked-arrow {
    rotate: 180deg;
}

.column-aerch-box-footer {
    height: 50px;
    background-color: red;
    width: 302px;
    height: 48px;
    position: absolute;
    bottom: 0;
    left: 0;

    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
}

.columnn-search-btn {
    width: 87.9px;
    height: 28px;
    margin-right: 10px;
}

.mt-spends-icon-activated {
    filter: brightness(100%) saturate(0%) invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%) !important;
}

.mt-find-icon-activated {
    filter: brightness(100%) saturate(0%) invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%) !important;
}

.mt-config-btns-container {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    justify-content: space-between;

}


.list-pages-search-input-container {
    display: flex;
    flex: 1;
    /* min-width: 230px; */
    min-width: 230px;
    /* width: 100%; */
    /* min-width: max-content; */   
}

.mt-config-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.mt-filter-btns {
    display: flex;
    justify-content: space-between;
}

.select-all-text label , .selcted-text-cont-text-for-col--search-mt {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    /* line-height: 16px; */
    color: #63677B;
    padding-left: 15px;
    font-weight: 400;
    color: #63677B;
    overflow-wrap: break-word;
}

.mt-column-search-rest-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #4D4184;
    padding-right: 5px;
}

.mt-colum-serach-reset-row {
    height: 250px;
    width: 100%;
    overflow-wrap: break-word;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 40px;
    margin-bottom: 50px;
}

.selected-row-count {
    /* width: 200px; */
    display: flex;
    justify-content: center;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #303346;
}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table {
    border-collapse: separate;
    border-spacing: 0;
}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tr .targetinng-table-heads {
    /* text-align: start; */
    /* padding: 12px 40px; */
    padding: 8px 20px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 40;
    opacity: 100%;
    border-right: 1px solid white;


}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tbody .targetinng-second-column {
    position: sticky;
    /* left: 68px; */
    left: 75px; /*MS-258 when horizonatal scroll 2nd clm not sticky */
    z-index: 21;
    width: 248px;
    background-color: white;
    padding: 4px 20px;
    height: auto;
    border-right: 1px solid #CCCC;
    box-shadow: 2px 1px 6px #cccc;
}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tr .targetinng-table-heads:nth-child(2) {
    text-align: center;
    /* padding: 12px 40px; */
    padding: 0px 20px;
    position: sticky;
    /* left: 126px; */
    /* left: 68px; */
     left: 75px; /*MS-258 when horizonatal scroll 2nd clm not sticky */
    top: 0;
    z-index: 45;
    opacity: 100%;
    border-right: 1px solid white;
    /* background-color: red; */
    justify-content: flex-start;
    border-right: 1px solid #e47518;
    box-shadow: 2px 1px 6px #c9705a91;


}




.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tbody tr td {
    border: none;
    border-right: 1px solid #CCCC;
    border-bottom: 1px solid #CCCC;
    padding: 8px 20px;
    text-align: left;

}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tbody tr .stats-data-col {
    text-align: end;
    /* padding-right: 35px; */
}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table thead tr .stats-heads {
    /* background-color: #4D4184; */
    text-align: end;

}

/* //first head that is ACTION */
.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table thead tr th:nth-child(1) {
    text-align: center;
    /* padding: 12px 40px; */
    padding: 8px 20px;
    position: sticky;
    /* top: 0; */
    z-index: 45;
    left: 0;
    /* height: 48px; */
    opacity: 100%;
    border-right: 1px solid white;
    width: 115px;
    position: sticky;

}

.mt-campaign-details {
    background: rgba(243, 241, 252, 0.3);
    background-color: #F2F7FE;
}

/* child first column fixed */
.mt-campaign-details td:nth-child(1) {
    position: sticky;
    /* z-index: 44; */
    z-index: 21;
    left: 0;
    background-color: #F2F7FE
}

.loader-td:nth-child(1) {
    background-color: white;
    position: sticky;
    left: 0px;
    top: 0;
    z-index: 44;

}

.loader-td:nth-child(2) {
    background-color: white;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 44;
    left: 68px;
    border-right: 1px solid #CCCC;
    box-shadow: 2px 1px 6px #cccc;

}

.child-camp-name {
    background-color: #4D4184;
}

.diable-click-event {
    pointer-events: none;
}

.campaign-text-column {
    /* width: 140px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-row-font {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tbody .mt-campaign-details td {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* background-color: palevioletred; */
}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tbody .first-column-sw-btn {
    position: sticky;
    left: 0;
    z-index: 35;
    opacity: 100%;
    /* display: flex;
  justify-content: center; */ 
     background-color: white !important;
    padding: 8px 20px;
}

.child-switch-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.child-switch-btn-container .form-check-input {
    background-color: #FFFFFF;
    border-color: #4D4184;
    background-image: url("../../../assets//icons//Handle (1).svg");
    border-radius: 35px;
    width: 28px;
    height: 16px;
    border: 2px solid rgba(77, 65, 132, 0.7);
    cursor: pointer;
}

.child-switch-btn-container .form-check-input:checked {
    width: 28px;
    height: 16px;
    background-color: #4D4184;
    border-color: #4D4184;
    border-radius: 35px;
    background-image: url("../../../assets//icons//white-circle-icon.png");
    cursor: pointer;
}

.vertical-bottom-gap {
    margin-bottom: 16px;
}

.mt-section-heading {
    margin-bottom: 16px;
}

.ennable-paused-btns-height {
    height: 32px;
    margin-right: 16px;
}

.no-data-found-text-container {
    position: absolute;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    left: 50%;
    margin-top: 100px;
    /* top: 50%; */
}


.margin-left-mt-filter-icon-container{
    margin-left: -5px;
}


.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table tr .targetinng-table-heads-adgrouptype-with-maginifine-icon {
    /* padding: 12px 40px; */
    padding: 8px 20px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 35;
    opacity: 100%;
    border-right: 1px solid white;
}

.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container .mt-table thead tr .active-filter-colmun {
    border: 2px solid #320F06;
    z-index: 44;

}

.clickable-text-color {
    color: #005AA4;
}

.mt-filter-gap {
    margin-left: 12px;
}

.search-close-icon-mt-container {
    position: relative;

}

.search-close-icon-mt {
    position: absolute;
    right: 20px;
    top: 6px;
}

.mt-status-dot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.enabled-status-dots-mt {

    width: 15px;
    height: 15px;
    background: #41993e;
    border-radius: 50%;
}

.paused-status-dots-mt {

    width: 15px;
    height: 15px;
    background: #bdbdc3;
    border-radius: 50%;
}

.show-selected-text {
    color: #63677B;
    ;
    justify-content: end;
    display: flex;
    font-size: 12px;
    padding-top: 5px;
}

.portfolio-input-sw-btn .form-check-input {
    width: 29px;
    height: 16px;
}

.portfolio-input-sw-btn .form-check-input:checked {
    width: 29px;
    height: 16px;
}

.show-selected-text{
    font-size: 12px;
color: #63677B;
}
.portfolio-input-sw-btn .form-check-input{
    width: 29px;
    height: 16px;
}
.portfolio-input-sw-btn .form-check-input:checked{
    width: 29px;
    height: 16px;
}

.show-selected-text{
    font-size: 12px;
color: #63677B;
}
.portfolio-input-sw-btn .form-check-input{
    width: 29px;
    height: 16px;
}
.portfolio-input-sw-btn .form-check-input:checked{
    width: 29px;
    height: 16px;
}

.empty-box-container {
    height: 40px;
    width: 40px;
}

.plus-icon-sec-container ,.plus-icon-sec-container-when-clicked{
    /* background-color: #320F06; */
    display: flex;
    justify-content: center;
    display: flex;
    background-color: transparent;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.plus-icon-sec-container:hover {
    background-color: #EEFCF6;
    /* height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; */
}

.plus-icon-sec-container-when-clicked:hover {
    background-color: #E3E3E4;
}

/* #E3E3E4 */
.label-extra-class {
    width: 170px;
    padding-left: 5px;
}
.find-icon-height{
    height: 20px;
}
.plus-icon-sec-container ,.plus-icon-sec-container-when-clicked{
    /* background-color: #320F06; */
    display: flex;
    justify-content: center;
    display: flex;
    background-color : transparent;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plus-icon-sec-container:hover{
    background-color : #F2F7FE;
    /* height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; */
}


.plus-icon-sec-container-when-clicked:hover{
    background-color : #F2F7FE;
}
/* #E3E3E4 */
.label-extra-class{
    width: 170px;
    padding-left: 5px;
}

.no-data-found-in-targeting-text-container{
    position: absolute;
    left: 50vh;
    top: 35vh;
}
.ndf-tabody-container{
    position: relative;
}


.colum-serach-top-row-show-selection{
    margin-left: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.colum-serach-top-row-show-checkbox-select-all{
    margin-left: 5px;
}

/* 23-05  table change ms96 */
.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container.head-bg-table table .fixed-table th.targetinng-table-heads
{
    background: #D9D9D9;
    /* padding: 12px 40px; MS-96 table header paddig*/
    padding: 0px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1C1B1F;
    box-shadow: none !important;
    border-right: 1px solid white !important;
}
.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container.head-bg-table table .fixed-table th .targetinng-typ
{
    background: none;
}
.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container.head-bg-table table .fixed-table th .other-col
{
    background: none;
}
.main-tableRb-body .tableRb .head-bg-table table thead th
{
    background: #D9D9D9;
    padding: 12px 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1C1B1F;
    box-shadow: none !important;
    border-right: 1px solid white !important;
}
.head-bg-table table thead th .check-colum-head label{ color: #1C1B1F;}
.main-tableRb-body .tableRb .head-bg-table table thead th.selected
{
    background: #625B71 !important;
    color:#fff !important;
}
.main-tableRb-body .tableRb .head-bg-table table thead th.selected .active-filter-higlighted-text {
    color: #fff !important;
}
.main-tableRb-body .tableRb .head-bg-table table thead th .find-icon-height
{
    filter: brightness(0) saturate(100%) invert(8%) sepia(12%) saturate(7179%) hue-rotate(340deg) brightness(94%) contrast(100%);
}
.main-tableRb-body .tableRb .head-bg-table table thead th img.cursor-pointer-global
{
    filter: brightness(0) saturate(100%) invert(8%) sepia(12%) saturate(7179%) hue-rotate(340deg) brightness(94%) contrast(100%);
}
.main-tableRb-body .tableRb .head-bg-table table thead th .cursor-pointer-global img
{
    filter: brightness(0) saturate(100%) invert(8%) sepia(12%) saturate(7179%) hue-rotate(340deg) brightness(94%) contrast(100%);
}
.main-tableRb-body .tableRb .head-bg-table table thead th.selected img.cursor-pointer-global.mt-find-icon-activated  {
    filter: brightness(100%) saturate(100%) invert(8%) sepia(12%) saturate(7179%) hue-rotate(550deg) brightness(150%) contrast(100%);
}
.main-tableRb-body .tableRb .head-bg-table table thead th.selected .mt-spends-icon-activated
{
    filter: brightness(100%) saturate(100%) invert(8%) sepia(12%) saturate(7179%) hue-rotate(550deg) brightness(150%) contrast(100%);
}
.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container.head-bg-table .mt-table tr .targetinng-table-heads:nth-child(2)
{
    left: 84px;
}
.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container.head-bg-table .mt-table tbody .targetinng-second-column
{
    left: 84px;
}
.targeting-list-table-data .targeting-list-main-table .main-targeting-table-body-container.head-bg-table .mt-table tbody .second-column-child
{
    left: 84px;
}
.main-tableRb-body .tableRb .head-bg-table table tbody tr td
{
    font-weight: 500;
    font-size: 12px;
    color: #4C646A;
    line-height: 16px;
}
.main-tableRb-body .tableRb .head-bg-table table tbody tr td .campaign-count-circle
{
    background: #4D4184;
    width: 20px;
    height: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    border-radius: 100px;
}
.main-tableRb-body .tableRb  table  tr th .targetinng-typ,
.main-tableRb-body .tableRb  table  tr th .other-col
{ background: none;}
.main-tableRb-body .tableRb  table  tr th .check-colum-head label {
    color: inherit;
}

.table-tab 
{
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #4D4184;
}
.table-tab button
{
    border: none;
    border-radius: 0px;
}
.table-tab button:first-child
{
    
    border-radius: 8px 0 0 8px;
}
.table-tab button:last-child
{
    
    border-radius:0 8px  8px 0;
}