.pie-container {
  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(194, 219, 224, 0.4);
  border-radius: 8px;
  width: 100%;
  height: 372px;
  padding-bottom: 15px;
}

.main-chart-holder {
  padding-bottom: 15px;
  height: 340px;
}

select {
  background: transparent;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAACXU8ZrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgBXcsxDgFBGAXg/59xAEfYRLFRKcQBdCSTzRxhS6bhBhs3UIljIAodagolotgjrN7Ms38xyWZf+d73eGrmViseHA+bFbWSWbf4efoqdPAAU24yVzSBsa7woGXQ4aI/z3vV6w/3TLxO01H3/bpdBQCUew7j025bcnxO7CzRUOcalyAkEcimIpJCBiJUTSD5AyDKPf2ZGzDaAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 50%;
}

.header-pie {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.sub-heading-pie {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  
  
  text-transform: capitalize;
  font-feature-settings: 'liga' off, 'kern' off;
  opacity: 0.8;
}

.pie-main {
  margin-top: 19px;
  margin-right: 50px;
  margin-left: 97px;
  margin-bottom: 23px;
}

.select-pie-type {
 

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 12px;
  gap: 8px;
  width: 141px;
  height: 36px;
  border: 1px solid #BDBDC3;
  border-radius: 24px;
  padding-top: 5px;
  color: #4C4F6A;
  font-weight: 500;
  font-size: 14px;
  
}
.select-pie-type:hover {
  background: rgba(229, 225, 249, 0.5);
  border-radius: 24px;
}
.pie-legend-sub {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 5px;
}

.pie-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  padding-bottom: 20px;
}

.legends-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
 
}

.pie-container-responsive {
  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(194, 219, 224, 0.4);
  border-radius: 8px;
  width: 100%;
  max-height: 372px;
  padding-bottom: 15px;
}


.main-chart-holder-responsive {
  padding-bottom: 15px;
  max-height: 340px;
}