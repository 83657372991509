.targetting-table-btn-container {
    margin-top: 20px;
    
}

.targetting-sec-table .target-keyword-col {
    text-align: start;
}

.targetting-sec-table table tr td {
    text-align: center;
    padding: 12px 40px;
    border:1px solid #CCCCCC;

}

.targetting-sec-table table tr th {
    text-align: center;
    padding: 12px 40px;
   position: sticky;
   /* border:1px solid grey; */
}

.targetting-sec-table table tr th:nth-child(3) {
    /* min-width: 200px; */
   /* border:1px solid grey; */
}

.targetting-sec-table table tr th:first-child {
    text-align: start;
    padding: 12px 24px;
}

.targetting-sec-table table tbody tr td:first-child {
    text-align: start;
    padding: 12px 24px;
    /* overflow:scroll; */

}

