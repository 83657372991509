.main-tableRb-body {
    display: flex;
    min-width: 300px;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    width: 100%;
    
    border-radius: 8px;
}

.main-tableRb-body::-webkit-scrollbar {
    display: none;
}


.main-table-head {
    position: sticky;
    top: 0;
}



.main-table-body-container::-webkit-scrollbar {
    display: none;
}

.main-table-body {
    /* height: 210px; */
    background: white;
    overflow-y: scroll !important;
}


.main-tableRb-body .tableRb {
    padding-bottom: 0px;
    /* background-color: #FF8364; */
    margin-bottom: 0px;
    border-top: none;
    border: none;
 
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 8px;

}
.asin-table .tableRb {
    border: none !important;
}
.asin-table.main-tableRb-body {
    border: 1px solid #CCCCCC !important;
}

.main-tableRb-body .tableRb table {
   
    /* background: white; */
    border-radius: 10px;
   
}

.main-tableRb-body .tableRb table thead {
    /* background-color: #FF8364;
    color: white; */
}



.main-tableRb-body .tableRb table thead th:nth-child(2)
{
    border-right: 1px solid #fff !important;
    box-shadow: none !important;
}
.main-tableRb-body .tableRb table thead th {
    /* background-color: #FF8364; MS-96 change bg color*/
    background-color: #C9CCCF;
    color: white;
    /* padding: 12px 20px !important;; MS-96 top bottom pading change*/
    padding: 0px 20px !important;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    text-align: left;
    border-right: 1px solid #fff !important;
    border-bottom: 1px solid #ccc !important;
    
    /* color: #FFFFFF; MS-96 change font color*/
    color: #1C1B1F;
    height: 36px;

}


.main-tableRb-body .tableRb tr {
    border-bottom: 1px solid #CCCCCC;
}

.main-tableRb-body .tableRb table tbody tr td {
    /* padding: 16px 24px; */
    padding: 4px 20px !important;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    /* font-size: 14px; MS-96 change font size in table inner data */
    font-size: 12px;
    line-height: 20px;
    /* color: #4C646A; */
    color: #1E2426;
    text-align: left;
}

.tabledata-td .input-group, .tabledata-td .input-group>.form-control {
    position: inherit;
}
.tabledata-td .w-max {
    min-width:max-content;
}
.fs-10 {
    font-size:11px;
}

/* MS-96 change icon color and when its selected then change bg and icon color */
.main-tableRb-body .tableRb  table{ 
    width: 100%;
}
.main-tableRb-body .tableRb  table thead th  img {
    filter: brightness(0) saturate(100%) invert(8%) sepia(12%) saturate(7179%) hue-rotate(340deg) brightness(94%) contrast(100%);
}

/* .main-tableRb-body .tableRb  table thead th.selected  img  {
    filter: brightness(100%) saturate(100%) invert(8%) sepia(12%) saturate(7179%) hue-rotate(550deg) brightness(150%) contrast(100%) !important;
} */
.main-tableRb-body .tableRb  table thead th.selected
{
    /* EDEEEF */
    background: #575959 !important;
    color: #fff !important;
}
/* .main-tableRb-body .tableRb  table thead th.selected .active-filter-higlighted-text{color:#fff;} */
.main-tableRb-body .tableRb  table thead .targetinng-second-column
{
    background-color: #C9CCCF;
    border-right: 1px solid #fff !important;
    box-shadow: none !important;
}
.main-tableRb-body .tableRb  table tbody .targetinng-second-column
{
    /* border-right: 1px solid #eee !important;
    box-shadow: none !important; */
}
.w-100.h-300.pie-container.p-2{height:auto;}
.filter-options{font-size: 12px;}
p.titleText
{
    font-weight: 700;
    font-size: 20px;
}
.sub-heading-pie.f-20.p-4
{
    font-size: 16px;
    line-height: 20px;
}
.f-table-body-container table thead th.filter-logic-main-table-tr
{
    background: #F4F6F8 !important;
    color:#1C1B1F !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    border-radius: 0 0 0 0;
}
.main-tableRb-body .tableRb  table tbody tr td .campaign-count-circle
{
    background: #FAFBFB;
    border: 1px solid #C9CCCF;
    /* width: 20px; */
    min-width: 32px;
    max-width: 40px;
    height: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #636F72;
    border-radius: 100px;
}
.line-column-graph-title + .d-flex.gap-3 {
    font-size: 12px;
}


.main-tableRb-body .tableRb table  tr.colspan-haed-row td.colspan-subhead{padding: 16px 20px !important;text-align: left;}
.main-tableRb-body .tableRb table  tr td.negative-data-comparison{text-align: left !important;}

.main-tableRb-body .tableRb table.campaign-list-center  tr th:nth-child(1),
.main-tableRb-body .tableRb table.campaign-list-center  tr th:nth-child(5)
{
    text-align: center !important;
}
.main-tableRb-body .tableRb table.main-targeting-center tr th:nth-child(8) .other-col
{
    justify-content: center;
}
.main-tableRb-body .tableRb table.campaign-list-center  tr td:nth-child(5) div.budget-td-container
{
    margin: auto;
}
td.adgroup-data-main-cotainer{align-items: center;}
.main-tableRb-body .tableRb  table  tr td.stats-data-col{text-align: start !important;}
.main-tableRb-body .tableRb  table  tr th.stats-heads{text-align: start !important;}
.main-tableRb-body .tableRb  table  tr th.stats-heads div{justify-content: flex-start!important;}
/* .main-tableRb-body .tableRb  table  tr th.targeting-table-heads div{justify-content: flex-start!important;} */
.main-tableRb-body .tableRb  table  tr th.targeting-table-heads div.select-reset-dropdown{justify-content: space-between!important;}
.main-tableRb-body .tableRb  table  tr th.targeting-table-heads div.form-check.form-check-inline{justify-content: center!important;}
th span.stat-heads-with-icon{margin-left: 0;}
/* .main-tableRb-body .tableRb table.marketing-reports thead th:nth-child(2){border-right: none !important;} */
th .innput-container-cb, td .innput-container-cb{height: auto;}

/* Table border radius issue */
.main-tableRb-body .tableRb table thead th:first-child{border-radius: 8px 0 0 0;}
.sp-product-targettig-outer-container .generic-main-table-body-container table{border:none !important;}
table tr.table-heading-style {
    background-color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 0px;
}
table.addetails-asins-table tr.table-heading-style {
    background-color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 8px;
}
 .top-2{ /*MS-258 table radius issue */
    position: relative;
    top: 3px;
}
.modal .main-tableRb-body .tableRb table.sp-product-targetting-table thead th:first-child {
    border-radius: 0px 0 0 0;
}
.main-tableRb-body .tableRb table thead tr:nth-child(2) th:first-child {
    border-radius: 0px 0 0 0;
}
tr.table-heading-style-asins{background-color: transparent;}
.main-tableRb-body .tableRb .main-campaignlist-table-body-container{border-radius: 8px;}
.main-tableRb-body .tableRb .main-targeting-table-body-container{border-radius: 8px;}
.main-tableRb-body .tableRb table thead th:last-child {
    border-radius: 0 8px 0 0;
    border-right: none !important; /* table header last column*/
}

.main-tableRb-body .tableRb table.reports thead th:last-child {
    border-radius: 0 0 0 0; /*MS-258 table corner issue */
    border-right: none !important;
}
/* Create campaign table extra border */
.table-bordered>:not(caption)>*>* {
    border-width: 0;
}
/* END chnage of MS-96 */
.main-tableRb-body .tableRb table.sp-product-targetting-table thead th:last-child {
    border-radius: 0 0px 0 0;
}
.main-tableRb-body .tableRb table.addetails-asins-table thead th:last-child {
    border-radius: 0 0px 0 0; /*MS-258 border issue*/
}
.table-body1 .main-tableRb-body .tableRb table thead{background: #fff;}
.table-body1 .main-tableRb-body .tableRb table td {
    border-radius: 0 !important;
    border-bottom: 1px solid #ccc;
    padding: 6px 20px !important;
}
.main-tableRb-body .tableRb table tr th.targeting-table-heads div.column-aerch-box-footer {
    justify-content: center!important;
}

.bg-secondary {
    cursor: not-allowed;
}
.bg-secondary:hover {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity))!important;
}

/* setup page new ui changes */
.tableRb .setup-table table thead th
{
    border-right: 1px solid #fff;
}
.tableRb .setup-table table thead tr th.targetinng-table-heads:nth-child(2)
{
    text-align: center;
    /* padding: 12px 40px; */
    padding: 8px 20px;
    position: sticky;
    /* top: 0; */
    z-index: 45;
    left: 100px;
    /* height: 48px; */
    opacity: 100%;
    border-right: 1px solid white;
    width: 115px;
    position: sticky;   
}

.setup-table .targeting-table-heads {
    padding: 8px 20px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 30;
    opacity: 100%;
    border-right: 1px solid white;
    text-align: left;
}

.setup-table tr td {
    border-right: 0.5px solid #cccccc;
    border-bottom: 0.5px solid #cccccc;
}

.master-ads-main-table-wrapper.setup-table .master-ads-table th:nth-child(1) {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 45;
    opacity: 100%;
    border-right: 1px solid #FFFFFF;
}
.master-ads-main-table-wrapper.setup-table .master-ads-table th:nth-child(2) {
    position: sticky;
    left: 121px;
    top: 0;
    z-index: 45;
    opacity: 100%;
    border-right: 1px solid #FFFFFF;
    /* padding-left: 20px; */
    padding: 0px 20px;
}
.master-ads-main-table-wrapper.setup-table .master-ads-table td:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 21;
    background-color: #fff;
    /* padding: 12px 15px; */
    height: auto;
}
.master-ads-main-table-wrapper.setup-table .master-ads-table td:nth-child(2) {
    position: sticky;
    left: 121px;
    z-index: 21;
    background-color: #fff;
    /* padding: 12px 15px; */
    height: auto;
}

@media screen and (max-width:1270px) {
    .main-tableRb-body .tableRb table thead th {
        padding: 5px 4px;
    }
}
