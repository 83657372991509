.iframe-style {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 94%;
    position: absolute;
    top: 61px;
    left: 110px;
    right: 0;
    bottom: 0;
    border: none;
}

.iframe-style-open {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 85%;
    position: absolute;
    top: 61px;
    left: 255px;
    right: 0;
    bottom: 0;
    border: none;
}